import React from "react"
import suumoGIF from "../gifs/logo-suumo.gif"

const LinksPartner = () => {
  return (
    <div className="columns pt-5">
      <div className="column has-text-centered">
        <a
          href="https://suumo.jp/baikyaku/kaisha/hokkaido/kc_175503001/kounyuu/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={suumoGIF} alt="" />
        </a>
      </div>
    </div>
  )
}

export default LinksPartner
