import { faSearchLocation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import LinksPartner from "../components/links-partner"
import Seo from "../components/seo"

const Buy = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="不動産の購入" description="株式会社ファーストの不動産購入" />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faSearchLocation} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          不動産の購入
        </h3>
        <section className="section">
          <h4 className="title is-4">永く満足できる暮らしを</h4>
          <p>
            住宅の購入は教育資金と老後資金とあわせて、人生の三大支出のひとつです。簡単に買い替えや買いなおしができないからこそ、満足度の高い住宅購入をして頂きたいと考えています。
          </p>
          <p className="block">
            当社では地域に密着した経験豊富な営業スタッフが、「永く満足できる暮らし」のために、住宅のご購入をサポート致します。
          </p>
          <div className="columns is-centered">
            <div className="column is-half">
              <StaticImage
                src="../images/flows/flow-buy.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>調査・提案</span>
                  <ul>
                    <li>
                      ご希望条件を元に打ち合わせをさせて頂き、購入物件をご提案します
                    </li>
                    <li>合わせて、資金計画のご相談も承ります</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>物件見学</span>
                  <ul>
                    <li>経験豊富なスタッフが物件をご案内致します</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>契約・引き渡し</span>
                  <ul>
                    <li>不動産売買契約を締結します</li>
                    <li>合わせて、住宅ローンの申し込みも承ります</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <p>
            不動産の購入には、手続きや税金などの専門的な知識が必要です。
            ご不明な点は当社のスタッフが丁寧に説明しますので、是非ご相談ください。
          </p>
          <p>
            お客様のご要望を踏まえ、最適な購入形態と資金計画をご提案致します。
          </p>
          <Link
            to="/contact"
            className="button is-dark is-fullwidth my-4 is-size-5"
            style={{ height: "4em" }}
          >
            お問い合わせはこちら　＞
          </Link>
          なお、弊社取り扱い中の物件は下記不動産サイトからご覧頂けます。
          <LinksPartner />
        </section>
      </div>
    </Layout>
  )
}

export default Buy
